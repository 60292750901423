import React, { useMemo } from "react"
import { Box, HStack, Image, Link, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { hasFlag } from "country-flag-icons"
import reactMarkdown from "react-markdown"
import { getSlug } from "utils/helpers"
import { Link as GatsbyLink } from "gatsby"

export const SupportEmail = () => (
  <Link href="mailto:sanctions-tracker@rdi.org" target="_blank" color="secondary">sanctions-tracker@rdi.org</Link>
)

export const CountryLabel = ({ country }) => {
  const flagSupported = useMemo(() => {
    return hasFlag(country.countryCode)
  }, [country])

  return (
    <HStack spacing="4">
      {flagSupported && (
        <Box padding="20px 0 20px 10px">
          <Image
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.countryCode}.svg`} 
            w={{base: "50px", md: "40px"}}
            h={{base: "50px", md: "40px"}}
            objectFit="cover"
            borderRadius="100%"
            maxW={{base: "50px", md: "40px"}}
            border="solid 1px lightgray"
          />
        </Box>
      )}
      <Link as={GatsbyLink} to={`/country/${getSlug(country.name)}`} replace >
        <Text pb="2px"   color="#373468" fontSize={{base: "xl", md: "xs", lg: "sm", xl: "md"}} lineHeight={{base: "xl", md: "xs", xl: "md"}} fontWeight="600" textDecor="underline" whiteSpace={{base: "normal", md: "normal", xl: "normal"}}>{country.name}</Text>
      </Link>
    </HStack>
  )

}

export const TextWithMargin = props => (
  <Text my="1" {...props} />
)

export const MarkdownText = ({ children, ...props }) => (
  <Text
    as={reactMarkdown}
    // whiteSpace="normal"
    fontSize="sm"
    linkTarget="_blank"
    components={{
      'ul': UnorderedList,
      'li': ListItem,
      'p': TextWithMargin
    }}
    {...props}
  >
    {children}
  </Text>
)