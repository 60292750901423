import React, { useMemo } from "react"
import { Badge, HStack, Icon, Text, VStack, Flex } from "@chakra-ui/react"
import { GiCommercialAirplane, GiRadioTower, GiTank } from 'react-icons/gi'
import { BiCheckCircle } from 'react-icons/bi'
import { statuses } from "utils/constants"
import { HiDesktopComputer } from "react-icons/hi"
import { IoIosCloseCircleOutline } from "react-icons/io"
import { BsSlashCircle, BsDashCircle } from "react-icons/bs"

export const StatusIcon = ({ icon = undefined, status }) => {
  switch (status) {
    case statuses.DONE:
      return (
        <Icon as={icon || BiCheckCircle} w={10} h={10} color="#17a074" fontWeight="bold" />
      )
    case statuses.PARTIAL:
      return (
        <Icon as={icon || BsSlashCircle} w={8} h={8} color="#f1a948" fontWeight="bold" />
      )
    case statuses.NOT_DONE:
      return (
        <Icon as={icon || IoIosCloseCircleOutline} w={10} h={10} color="#ff6972" fontWeight="bold" />
      )
    case statuses.NOT_APPLICABLE:
      return (
        <Icon as={icon || BsDashCircle} w={8} h={8} color="#e1e1e2" fontWeight="bold" />
      )
    default:
      return null
  }
}

export const StatusBadge = ({ status, fontSize = '0.8em', text = undefined }) => {
  switch (status) {
    case statuses.DONE:
      return (
        <Badge fontSize={fontSize} bg="#f8f8fb" color="#4a9d77" p="5px 10px" borderRadius="60px" m="10px 0" fontWeight="bold" >
          <Flex justifyContent="space-between" alignItems="center">
            <Flex mr="5px" justifyContent="center" alignItems="center">
              <Icon as={BiCheckCircle} w={5} h={5} color="#4a9d77" fontWeight="bold" />
            </Flex>
            {text || 'On Track'}
          </Flex>
        </Badge>
      )
    case statuses.PARTIAL:
      return (
        <Badge fontSize={fontSize} bg="#f8f8fb" color="#f1a948" p="5px 10px" borderRadius="60px" m="10px 0" fontWeight="bold" >
          <Flex justifyContent="space-between" alignItems="center">
            <Flex mr="5px" justifyContent="center" alignItems="center">
              <Icon as={BsSlashCircle} w={4} h={4} color="#f1a948" fontWeight="bold" />
            </Flex>
            {text || 'Partially Done'}
          </Flex>
        </Badge>
      )
    case statuses.NOT_DONE:
      return (
        <Badge fontSize={fontSize} bg="#f8f8fb" color="#ff2f3c" p="5px 10px" borderRadius="60px" m="10px 0" fontWeight="bold" >
          <Flex justifyContent="space-between" alignItems="center">
            <Flex mr="5px" justifyContent="center" alignItems="center">
              <Icon as={IoIosCloseCircleOutline} w={5} h={5} color="#ff2f3c" fontWeight="bold" />
            </Flex>
            {text || 'Not Yet Done'}
          </Flex>
        </Badge>
      )
    case statuses.NOT_APPLICABLE:
      return (
        <Badge fontSize={fontSize} bg="#f8f8fb" color="#d6d6d6" p="5px 10px" borderRadius="60px" m="10px 0" fontWeight="bold" >
          <Flex justifyContent="space-between" alignItems="center">
            <Flex mr="5px" justifyContent="center" alignItems="center">
              <Icon as={BsDashCircle} w={4} h={4} color="#d6d6d6" fontWeight="bold" />
            </Flex>
            {text || 'Not Applicable'}
          </Flex>
        </Badge>
      )
    default:
      return null
  }
}

export const GradeBadge = ({ status, grade }) => {
  const color = useMemo(() => {
    switch (status) {
      case statuses.DONE: return '#17a074'
      case statuses.PARTIAL: return '#f1a948'
      case statuses.NOT_DONE: return '#eb0f1d'
      case statuses.NOT_APPLICABLE:
      default:
        return 'gray'
    }
  }, [status])

  return (
    <Flex
      borderRadius="100%"
      bgColor={color}
      px="2"
      py="1"
      color="white"
      h="45px"
      w="45px"
      justifyContent="center"
      alignItems="center"
      boxShadow={`0px 0px 1px 7px ${color}4d`}
    >
      <Text
        fontSize="xl"
        fontWeight="bold"
        cursor="default"
        
        // variant="outline"
      >
        {grade}
      </Text>
    </Flex>
  )
}

export const GradeBadgeInternal = ({ status, grade }) => {
  const color = useMemo(() => {
    switch (status) {
      case statuses.DONE: return '#17a074'
      case statuses.PARTIAL: return '#f1a948'
      case statuses.NOT_DONE: return '#eb0f1d'
      case statuses.NOT_APPLICABLE:
      default:
        return 'gray'
    }
  }, [status])

  const finalStatus = useMemo(() => {
    switch (status) {
      case statuses.DONE: return 'On Track'
      case statuses.PARTIAL: return 'Partially Done'
      case statuses.NOT_DONE: return 'Not Yet Done'
      case statuses.NOT_APPLICABLE: return 'Not Applicable'
      default:
        return 'gray'
    }
  }, [status])

  return (
    <Flex
      borderRadius="60px"
      bgColor={`${color}4d`}
      px="2"
      py="1"
      color="white"
      h="55px"
      w="100%"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Flex
        borderRadius="100%"
        bgColor={color}
        px="2"
        py="1"
        color="white"
        h="40px"
        w="40px"
        justifyContent="center"
        alignItems="center"
        border="solid 1px white"
      >
        <Text
          fontSize="2xl"
          fontWeight="bold"
          cursor="default"
          
          // variant="outline"
        >
          {grade} 
        </Text>
        
      </Flex>
      <Flex>
        <Text  fontWeight="bold" fontSize={{base:"xl", lg: "xl"}} pl="20px" color={color} textTransform="uppercase">
          {finalStatus}
        </Text>
      </Flex>
    </Flex>
  )
}

export const MilitaryIcons = ({ status }) =>
  status ? (
    <VStack spacing={2}>
      <HStack>
        <StatusIcon icon={GiTank} status={status.groundSupport} />
        <StatusIcon icon={GiCommercialAirplane} status={status.antiAir} />
      </HStack>
      <HStack>
        <StatusIcon icon={GiRadioTower} status={status.communications} />
        <StatusIcon icon={HiDesktopComputer} status={status.cyber} />
      </HStack>
    </VStack>
  ) : (
    <StatusIcon status={statuses.NOT_APPLICABLE} />
  )
