import React from 'react'
import { Box, ButtonGroup, Container, IconButton, Link, Stack, Text } from '@chakra-ui/react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'

export const Footer = () => (
  <Box
    bgColor="#000000"
    p={{ base: '20px 20px', md: '42px 30px' }}
    mt="50px"
    color="white"
  >
    <Container maxW="7xl" as="footer" role="contentinfo">
      <Stack spacing={{ base: '4', md: '5' }}>
        <Stack justify="space-between" direction={{base: "column", md: "row"}} align="center">
          <Box>
            <Text fontSize="xs" color="subtle"  fontWeight="light" textTransform="uppercase" letterSpacing="2px">
              The Renew Democracy Initiative, Inc is a 501(c)(3) not-for-profit organization.
            </Text>
            <Text fontSize="xs" color="subtle"  fontWeight="light" textTransform="uppercase" letterSpacing="2px" mb={{base: "40px", md: "0px"}}>
              &copy; {new Date().getFullYear()} Renew Democracy Initiative, Inc. All rights reserved. | <Link href="https://rdi.org/rdi-privacy-policy/" target="_blank" fontWeight="bold">Privacy Policy</Link>
            </Text>
          </Box>
          <ButtonGroup variant="ghost" >
            <IconButton
              as="a"
              href="https://twitter.com/Renew_Democracy"
              aria-label="Twitter"
              icon={<FaTwitter fontSize="1.5rem" />}
              _hover={{color: "secondary"}}
            />
            <IconButton
              as="a"
              href="https://www.instagram.com/renewdemocracy/"
              aria-label="Instagram"
              icon={<FaInstagram fontSize="1.5rem" />}
              _hover={{color: "secondary"}}
            />
            <IconButton
              as="a"
              href="https://www.linkedin.com/company/renew-democracy-initiative/"
              aria-label="LinkedIn"
              icon={<FaLinkedin fontSize="1.5rem" />}
              _hover={{color: "secondary"}}
            />
            <IconButton
              as="a"
              href="https://www.facebook.com/RenewDemocracyInitiative/"
              aria-label="Facebook"
              icon={<FaFacebook fontSize="1.5rem" />}
              _hover={{color: "secondary"}}
            />
            <IconButton
              as="a"
              href="https://www.youtube.com/channel/UC50XfwMD05I0ey_rp5teDOw"
              aria-label="YouTube"
              icon={<FaYoutube fontSize="1.5rem" />}
              _hover={{color: "secondary"}}
            />
          </ButtonGroup>
        </Stack>
      </Stack>
    </Container>
  </Box>
)
