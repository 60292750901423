import React from 'react'

export default function SignUpStyles() {
  return (
    <>
        <style>
            {
            `
                .share__icon path {
                  fill: #0056bd;
                }
                .share__icon.placeholder{
                  opacity: 0;
                }
                .ngp-form{
                  width: 100%;
                  max-width: 800px;
                  border-radius: 50px;
                }
                .ngp-form *{
                  font-family: "Poppins"!important;
                }
                .chakra-accordion__icon{
                  display: none!important;
                }
                .ngp-form .at-inner{
                  padding: 20px 25px;
                }
                .at-submit{
                  font-family: "Poppins"!important;
                  background: #9a307d!important;
                  border-radius: 0!important;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                }
                @media(max-width: 600px){
                  .chakra-modal__content{
                    width: 95%!important;
                  }
                  .ngp-form .at-inner{
                    padding: 20px 0px;
                  }
                }
            `
            }
        </style>
    </>
  )
}
