import React, { useContext, useEffect, useRef } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { ModalContext } from 'utils/context'
import SignUpStyles from '../common/SignUpStyles'
// Provided Embed Code:
// <link rel='preload' href='https://static.everyaction.com/ea-actiontag/at.js' as='script' crossorigin='anonymous'>
//  <link rel='preload' href='https://static.everyaction.com/ea-actiontag/at.min.css' as='style'>
//  <script type='text/javascript' src='https://static.everyaction.com/ea-actiontag/at.js' crossorigin='anonymous'></script>
//  <div class="ngp-form"
//      data-form-url="https://secure.everyaction.com/v1/Forms/niNSMwtg1UaNM5znlU57Hw2"
//      data-fastaction-endpoint="https://fastaction.ngpvan.com"
//      data-inline-errors="true"
//      data-fastaction-nologin="true"
//      data-databag-endpoint="https://profile.ngpvan.com"
//      data-databag="everybody"
//           data-mobile-autofocus="false">
// </div>

export const EveryActionModal = ({ contextKey, dataFormUrl }) => {
  const context = useContext(ModalContext)
  const { isOpen, onClose } = context[contextKey]
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.everyaction.com/ea-actiontag/at.js";
    document.body.appendChild(script);
  }, [])
  const formRef = useRef(null)
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        // @ts-ignore
        window.nvtag.process(formRef.current)
      }, 500)
    }
  }, [isOpen])
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered autoFocus>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton zIndex={100} />
        <ModalBody minH="300px">
          <div
            ref={formRef}
            className="ngp-form"
            data-form-url={dataFormUrl}
            data-fastaction-endpoint="https://fastaction.ngpvan.com"
            data-inline-errors="true"
            data-fastaction-nologin="true"
            data-databag-endpoint="https://profile.ngpvan.com"
            data-databag="everybody"
            data-mobile-autofocus="false"
          />
        </ModalBody>
        <SignUpStyles />
      </ModalContent>
    </Modal>
  )
}
