export const statuses = {
  NOT_DONE: 0,
  NOT_APPLICABLE: 1,
  PARTIAL: 2,
  DONE: 3,
}

export const statusDB = {
  'Not Yet Done': 0,
  'Not Applicable': 1,
  'Partially Done': 2,
  'Done': 3,
}

export const EuropeanUnion = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE']

export const getIsEU = code => EuropeanUnion.indexOf(code) > -1;

// export const bannerImage = 'https://i.ibb.co/cgnBK40/karollyne-hubert-l4-J479vfru4-unsplash.jpg'
export const bannerImage = 'https://cdn.pixabay.com/photo/2022/02/24/06/46/banner-7031868_960_720.jpg'

export const militaryId = 'd7b55baf-b3bd-5af8-af39-0804feb7b0f8'

export const ProposalListId = '9c731be6-0dce-5011-8964-7e880f084406'
export const CountryListId = '9213edf0-f7f1-52e7-ac9d-7465da88271d'