import { statuses, statusDB, getIsEU, militaryId } from "./constants"
import { isArrayLength } from "./helpers"

export const getTrackerData = (data) => {
  const allProposals = data.proposalList.entries.map(proposal => ({
    id: proposal.id,
    title: proposal.title,
    shortTitle: proposal.shortTitle || proposal.title,
    description: proposal.description?.internal.content,
    criteria: {
      done: proposal.criteriaDone?.internal.content,
      partial: proposal.criteriaPartial?.internal.content,
      notDone: proposal.criteriaNotDone?.internal.content,
    }
  }))
  const allActions = []
  const allActionsMapped = {}
  const allCountries = []
  // let euCountry
  // const euCountries = []
  const countries = []
  
  data.countryList.entries.forEach(country => {
    const isEU = getIsEU(country.countryCode)

    try {
      let actionsMapped = {}
      country.action && country.action.forEach(action => {
        if (!actionsMapped[action.proposal.id]) {
          actionsMapped[action.proposal.id] = []
        }
        let date, timestamp
        if (action.actionDate) {
          let dateObj = new Date(action.actionDate)
          date = dateObj.toLocaleDateString()
          timestamp = dateObj.getTime()
        }
        if (!action.description) {
          console.log("action stuff missing!", action)
          return
        }
        let status = statusDB[action.status.title]
        if (!action.reportCard) {
          actionsMapped[action.proposal.id].push({
            id: action.id,
            description: action.description?.internal.content,
            links: action.links,
            status,
            date,
            timestamp,
          })
        }
        if (!allActionsMapped[action.id]) {
          allActionsMapped[action.id] = true
          allActions.push({
            id: action.id,
            description: action.description?.internal.content,
            links: action.links,
            status,
            date,
            timestamp,
            countries: action.countries,
            proposal: action.proposal,
          })
        }
      })

      let statusesMapped = {}
      if (isArrayLength(country.proposal_status)) {
        country.proposal_status.forEach(propStatus => {
          statusesMapped[propStatus.proposal.id] = {
            status: statusDB[propStatus.status.title],
            description: propStatus.description?.internal?.content
          }
        })
      }
  
      let military
      let proposals = allProposals.map(proposal => {
        let actions = actionsMapped[proposal.id] || []
        actions.sort((a, b) => {
          if (!a.timestamp && !b.timestamp) {
            return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
          }
          return a.timestamp < b.timestamp ? 1 : -1
        })        
        let status = statusesMapped[proposal.id] || {
          status: Math.max(...actions.map(a => a.status), isEU ? statuses.NOT_APPLICABLE : statuses.NOT_DONE)
        }
        if (proposal.id === militaryId) {
          if (isArrayLength(country.military_status)) {
            let ms = country.military_status[0]
            military = {
              groundSupport: statusDB[ms.groundSupport.title],
              antiAir: statusDB[ms.antiAir.title],
              communications: statusDB[ms.communications.title],
              cyber: statusDB[ms.cyber.title],
              description: ms.description?.internal.content,
            }
          }
        }

        return {
          id: proposal.id,
          title: proposal.title,
          description: proposal.description,
          status,
          actions,
          military
        }
      })

      let grade
      if (isArrayLength(country.overall_grade)) {
        grade = {
          grade: country.overall_grade[0].grade,
          status: country.overall_grade[0].status ? statusDB[country.overall_grade[0].status.title] : statuses.NOT_APPLICABLE,
          description: country.overall_grade[0].description?.internal.content
        }
      } else {
        grade = {
          status: statuses.NOT_APPLICABLE
        }
      }

      let countryObj = {
        id: country.id,
        name: country.name,
        countryCode: country.countryCode,
        proposals,
        grade,
        isEU,
      }

      // No nesting for now
      // if (isEU) {
      //   euCountries.push(countryObj)
      // } else {
      //   countries.push(countryObj)
      // }
      // if (country.countryCode === 'EU') {
      //   euCountry = countryObj
      // }
      countries.push(countryObj)
      allCountries.push(countryObj)
    } catch (e) {
      console.error('Error parsing countries', e)
      return null
    }
  })

  allActions.sort((a, b) => {
    if (!a.timestamp && !b.timestamp) {
      return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
    }
    return a.timestamp < b.timestamp ? 1 : -1
  })

  // if (euCountry) {
  //   // @ts-ignore
  //   euCountry.countries = euCountries
  // }

  return {
    countries: countries,
    allCountries,
    proposals: allProposals,
    actions: allActions
  }
}

export const getCountryData = ({ country, proposalList }) => {
  let actionsMapped = {}
  const actions = (country.action || []).map(action => {
    console.log(action)
    let date, timestamp
    if (action.actionDate) {
      let dateObj = new Date(action.actionDate)
      date = dateObj.toLocaleDateString()
      timestamp = dateObj.getTime()
    }
    if (!action.description) {
      console.log("action stuff missing!", action)
      // return
    }
    let status = statusDB[action.status.title]
    
    return {
      id: action.id,
      description: action.description?.internal.content,
      links: action.links,
      status,
      date,
      timestamp,
      countries: action.countries,
      proposal: action.proposal,
    }
  })

  const statusesMapped = {}
  if (isArrayLength(country.proposal_status)) {
    country.proposal_status.forEach(propStatus => {
      statusesMapped[propStatus.proposal.id] = {
        status: statusDB[propStatus.status.title],
        description: propStatus.description?.internal?.content
      }
    })
  }

  const isEU = getIsEU(country.countryCode)
  const proposals = proposalList.entries.map(proposal => {
    
    let propAct = [];

    if(country.action){
      propAct = country.action.filter(action => action.proposal.title === proposal.title)
    }

    let proposalActions = actionsMapped[proposal.id] || []
    proposalActions.sort((a, b) => {
      if (!a.timestamp && !b.timestamp) {
        return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
      }
      return a.timestamp < b.timestamp ? 1 : -1
    })        
    let status = statusesMapped[proposal.id] || {
      status: Math.max(...actions.map(a => a.status), isEU ? statuses.NOT_APPLICABLE : statuses.NOT_DONE)
    }

    return {
      id: proposal.id,
      title: proposal.title,
      description: proposal.description?.internal.content,
      status,
      actions: propAct,
    }
  })

  let grade
  if (isArrayLength(country.overall_grade)) {
    grade = {
      grade: country.overall_grade[0].grade,
      status: country.overall_grade[0].status ? statusDB[country.overall_grade[0].status.title] : statuses.NOT_APPLICABLE,
      description: country.overall_grade[0].description?.internal.content
    }
  } else {
    grade = {
      status: statuses.NOT_APPLICABLE
    }
  }

  actions.sort((a, b) => {
    if (!a.timestamp && !b.timestamp) {
      return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
    }
    return a.timestamp < b.timestamp ? 1 : -1
  })

  return {
    id: country.id,
    name: country.name,
    countryCode: country.countryCode,
    actions,
    proposals,
    grade,
  }
}