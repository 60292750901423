import React from "react"
import { Box, Container, Flex, useDisclosure } from "@chakra-ui/react"
import { Footer } from "./Footer"
import { ModalContext } from "utils/context"
import { EveryActionModal } from "components/Modals/EveryActionModal"
import GoogleFonts from "../common/Fonts"

export const Shell = ({ children }) => {
  const subscribeDisclosure = useDisclosure()
  const donateDisclosure = useDisclosure()
  return (
    <ModalContext.Provider value={{ subscribe: subscribeDisclosure, donate: donateDisclosure }}>

      <GoogleFonts />
      <Flex direction="column" minHeight="100vh" bg="#fdfdfd">
        <Box as="main" py="0" flex="1" >
          <Container maxW="100%" p="0">
            {children}
          </Container>
        </Box>
        <Footer />
      </Flex>
      <EveryActionModal
        contextKey="subscribe"
        dataFormUrl="https://secure.everyaction.com/v1/Forms/niNSMwtg1UaNM5znlU57Hw2"
      />
    </ModalContext.Provider>
  )
}